<template>
    <section class="proveedores-ver-ordenes container-fluid overflow-auto custom-scroll mt-2">
        <titulo-divisor titulo="Ordenes de compra">
            <div class="row">
                <div class="col auto d-none">
                    <el-input v-model="buscarListado" placeholder="Buscar cotizaciones" size="small" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="toggleFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="toggleFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">{{ cantFiltros }}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <div class="d-flex justify-content-lg-end">
            <search class="w-25" :data-to-search="listadoOrdenesCompra.data" :auto-search="false" placeholder="Buscar orden de compra" @filtered="refreshBuyOrdersList" />
        </div>
        <tabla-general :data="listadoOrdenesCompra.data" :usar-paginacion="true" :mostrar-buscador="false" :servidor-data="listadoOrdenesCompra">
            <el-table-column label="Orden" prop="id" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600">
                        {{ scope.row.id }}
                    </p>
                </template>
            </el-table-column>
             <el-table-column label="Proyecto" prop="oportunidad">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.oportunidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="cliente">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        <i :class="`f-18 ${purchase_states[scope.row.estado].icon || 'icon-information-outline'}`" />
                        {{ purchase_states[scope.row.estado].text || '' }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Pago" prop="valor">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.valor }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Proyecto</p>
                    <el-select v-model="filter.id_oportunidad" placeholder="Seleccionar proyecto" size="small" class="w-100" clearable>
                        <el-option v-for="oportunidad in listadoOportunidadesOc" :key="oportunidad.id" :label="oportunidad.oportunidad" :value="oportunidad.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Cliente</p>
                    <el-select v-model="filter.id_cliente" placeholder="Seleccionar cliente" size="small" class="w-100" clearable>
                        <el-option v-for="cliente in listadoClientesOc" :key="cliente.id" :label="cliente.cliente" :value="cliente.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filter.estado" placeholder="Seleccionar estado" size="small" class="w-100" clearable>
                        <el-option v-for="(item, index) in select_states" :key="`state-${index}`" :label="item.name" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_value" range :min="range[0]" :max="range[1]" :format-tooltip="formatTooltipRange" :disabled="disableSlider" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="handleFilter"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ProviderPurchaseOrderFilterFactory } from '~/domain/filters/proveedores/ordenes_compra/factories/ProviderPurchaseOrderFilterFactory'
import { getRange } from '~/services/global'

export default {
    data(){
        return{
            buscarListado:'',
            range: [0,0]
        }
    },
    computed: {
        ...mapGetters({
            purchase_states: 'compras/ordenes_compra/purchase_states',
            select_states: 'compras/ordenes_compra/select_states',
            listadoOrdenesCompra: 'proveedores/ver/ver/provider_buy_orders',
            listadoOportunidadesOc: 'proveedores/ver/ordenes_compra/oportunities',
            listadoClientesOc: 'proveedores/ver/ordenes_compra/clients',
            // range: 'proveedores/ver/ver/range'
        }),
        filter:{
            get(){
                return this.$store.getters['proveedores/ver/ordenes_compra/filter']
            },
            set(val){
                this.$store.commit('proveedores/ver/ordenes_compra/set_filter', val)
            }
        },
        range_value:{
            get(){
                return [this.filter.valor_inicial, this.filter.valor_final]
            },
            set(val){
                this.filter.valor_inicial = val[0]
                this.filter.valor_final = val[1]
            }
        },
        id_proveedor(){
            return this.$route.params?.id_proveedor
        },
        cantFiltros(){
            return this.filter.count()
        },
        disableSlider(){
            return this.range.every(el => el === 0)
        }
    },
    async mounted(){
        this.getStates();
        const filter = ProviderPurchaseOrderFilterFactory.instantiateEmpty()
        
        await Promise.all([
            this.obtenerOportunidades({id_proveedor: this.id_proveedor}),
            this.obtenerClientes({id_proveedor: this.id_proveedor}),
        ]);

        const { 
            estado, 
            id_oportunidad, 
            id_cliente, 
            valor_inicial, 
            valor_final 
        } = this.$route.query
        
        this.filter = ProviderPurchaseOrderFilterFactory.instantiate({
            estado: estado ?? filter.estado,
            id_cliente: id_cliente ?? filter.id_cliente,
            id_oportunidad: id_oportunidad ?? filter.id_oportunidad,
            valor_inicial: valor_inicial ?? filter.valor_inicial,
            valor_final: valor_final ?? filter.valor_final
        })
    
        await this.filtrarOrdenesCompra()

        this.range = getRange(this.listadoOrdenesCompra.data, 'valor')
    },
    methods:{
        ...mapActions({
            obtenerListadoOrdenesCompra: 'proveedores/ver/ver/Action_get_provider_buy_orders',
            regenerarListadoOrdenesCompra: 'proveedores/ver/ver/Action_regenerate_provider_buy_orders',
            obtenerOportunidades: 'proveedores/ver/ordenes_compra/Action_get_buy_orders_opportunities',
            obtenerClientes: 'proveedores/ver/ordenes_compra/Action_get_buy_orders_clients',
            getStates: 'compras/ordenes_compra/Action_get_select_states',
            clearFilter: 'proveedores/ver/ordenes_compra/clear_filter',
        }),
        ...mapMutations({
            setListadoOrdenesCompra: 'proveedores/ver/ver/set_provider_buy_orders'
        }),

        toggleFilterModal(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filtrarOrdenesCompra(){
            this.$router.push({query: this.filter.clean()})
            await this.obtenerListadoOrdenesCompra({
                id_proveedor: this.id_proveedor, 
                payload: this.filter.clean()
            })
        },
        handleFilter(){
            this.filtrarOrdenesCompra()
            this.toggleFilterModal()
        },
        async limpiarFiltro(){
            this.clearFilter()
            this.filtrarOrdenesCompra()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async refreshBuyOrdersList(data){
            if(data.length === 0) return this.regenerarListadoOrdenesCompra()
            this.setListadoOrdenesCompra({...this.listadoCotizaciones, data})
        },
    }

}
</script>

<style lang="scss" scoped>
.proveedores-ver-ordenes{
    height: calc(100vh - 445px);
    .icon-file-send{
        &::before{
            margin-left: 0px;
        }
    }
}
</style>